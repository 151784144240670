const OUTSIDE = 0;
const DIRTY = 1;
const CLEAN = 2;

class Room {
    squares;

    constructor(squares) {
        this.squares = squares;
    }

    clean(x, y) {
        this.squares[y][x] = CLEAN;
    }

    isClean() {
        for (let y = 0; y < this.squares.length; y++) {
            for (let x = 0; x < this.squares[y].length; x++) {
                if (this.squares[y][x] === DIRTY) {
                    return false;
                }
            }
        }
        return true;
    }

    copy() {
        let squaresCopy = JSON.parse(JSON.stringify(this.squares));
        return new Room(squaresCopy);
    }
}

class Robot {
    x;
    y;
    direction;

    constructor(x, y, direction, room) {
        this.x = x;
        this.y = y;
        this.direction = direction;
        this.room = room;
    }

    angle() {
        return toAngle(this.direction);
    }

    move(steps) {
        this.x += dx(this.angle(), steps);
        this.y += dy(this.angle(), steps);
    }

    rotate(quarterTurns) {
        this.direction += quarterTurns;
    }

    sense(room, direction) { // 0: ahead, -1: left, 1: right
        let angle = toAngle(this.direction + direction);
        // assume we are "inside" the room and the room is
        // surrounded by at least one band of "outside" tiles.
        let x = this.x + dx(angle, 1);
        let y = this.y + dy(angle, 1);
        return room.squares[y][x];
    }

    copy() {
        return new Robot(this.x, this.y, this.direction);
    }
}

/**
 * Converts a "direction" in quarter turns to an angle in
 * radians.
 */
function toAngle(direction) {
    return direction * Math.PI/2;
}

function dx(angle, steps) {
    return Math.round(Math.cos(angle) * steps);
}

function dy(angle, steps) {
    return Math.round(Math.sin(angle) * steps);
}

class Situation {
    constructor(roomDef) {
        let robot;
        for (let y = 0; y < roomDef.length; y++) {
            for (let x = 0; x < roomDef[y].length; x++) {
                if (typeof roomDef[y][x] === 'string') {
                    let direction = {
                        EAST: 0,
                        SOUTH: 1,
                        WEST: 2,
                        NORTH: 3
                    }[roomDef[y][x]];
                    robot = new Robot(x, y, direction);
                    roomDef[y][x] = CLEAN;
                }
            }
        }
        let room = new Room(roomDef);
        this.room = function () {
            return room.copy();
        };
        this.robot = function () {
            return robot.copy();
        };
    }
}

class Level {
    situations;
    maxBlocks;
    blockTypes;

    constructor(situations, maxBlocks, blockTypes) {
        this.situations = situations;
        this.maxBlocks = maxBlocks;
        this.blockTypes = blockTypes;
    }
}

export { OUTSIDE, DIRTY, CLEAN, Room, Robot, Situation, Level };
